// eslint-disable-next-line
import { graphql } from '~/graphql-query'
import { isEmpty, isNil } from 'ramda'
import { PageLayout } from '../components/Layout'
import { ContentRows, ContentEntry } from '../components/ContentRows'
import { Faq } from '../components/FAQ'
import { Announcements, AnnouncementItem } from '../components/Announcements'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'

const GenericPage = ({
  pageContext: {
    data: {
      content: { page },
    },
  },
}) => {
  const {
    hero,
    covidBannerLayout,
    miniBookReference,
    contentRows,
    sideBar,
    bottomSection,
    faqSection,
  } = page
  const pagePath = typeof window !== 'undefined' && window.location.pathname

  return (
    <>
      <PageLayout>
        {hero && (
          <>
            <Hero {...hero.contentRef} miniBookReference={miniBookReference} />

            {covidBannerLayout?.localisedContentReference?.header &&
              typeof sessionStorage !== 'undefined' &&
              sessionStorage.covidPaxBannerNotVisible !== 'true' && (
                <PaxCovidBanner
                  {...covidBannerLayout?.localisedContentReference}
                />
              )}
          </>
        )}
      </PageLayout>
      <PageLayout
        sidebar={
          !isNil(sideBar) &&
          !isEmpty(sideBar.items) &&
          sideBar.items.map((item) => (
            <ContentEntry id={item.sys.id} key={item.sys.id} spaced {...item} />
          ))
        }
      >
        {pagePath === '/en/about/investors/announcements' && <Announcements />}
        {pagePath === '/en/about/investors/announcements/announcement' && (
          <AnnouncementItem />
        )}

        {contentRows && <ContentRows contentRows={contentRows} />}
      </PageLayout>
      <PageLayout>
        {bottomSection && <ContentRows contentRows={bottomSection} />}
        {faqSection?.itemsCollection?.items &&
          faqSection?.itemsCollection?.items.length !== 0 && (
            <Faq
              items={faqSection?.itemsCollection?.items}
              heading={faqSection?.heading}
              urlSlug={faqSection?.urlSlug?.slug}
              defaultExpanded={faqSection?.defaultExpanded ?? false}
            />
          )}
      </PageLayout>
    </>
  )
}

export const query = graphql`
  query GenericPageHeader(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: genericPage(id: $id, locale: $locale, preview: $preview) {
        hero: heroLayout {
          ... on contentful_Entry {
            __typename
          }
          contentRef {
            ...PaxHeroContent
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        miniBookReference {
          ...Minibook
          ...MinibookProductList
        }
      }
    }
  }

  query GenericPageSidebar(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: genericPage(id: $id, locale: $locale, preview: $preview) {
        sideBar: sideArmCollection(limit: 5) {
          items {
            ... on contentful_Entry {
              __typename
              sys {
                id
              }
            }
            ... on contentful_XSpaceRefComponent {
              component
            }
            ...InfoBlock
            ...ContactBox
            ...TextAndMediaLayout
            ...PromoBlock
            ...IframeBlock
            ...FormForgetMe
            ...PageContentTextSection
          }
        }
      }
    }
  }

  query GenericPage($id: String!, $locale: String!, $preview: Boolean = false) {
    content: contentful {
      page: genericPage(id: $id, locale: $locale, preview: $preview) {
        contentRows: contentRowsCollection(limit: 12) {
          items {
            ... on contentful_Entry {
              __typename
              sys {
                id
              }
            }
            ...ContentRows
            ...LocalizedRow
          }
        }
      }
    }
  }

  query GenericPageSecond(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: genericPage(id: $id, locale: $locale, preview: $preview) {
        contentRows: contentRowsCollection(limit: 12) {
          items {
            ... on contentful_Entry {
              __typename
              sys {
                id
              }
            }
            ...ContentRowsSecond
          }
        }
      }
    }
  }

  query GenericPageForms(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: genericPage(id: $id, locale: $locale, preview: $preview) {
        contentRows: contentRowsCollection(limit: 3) {
          items {
            ... on contentful_Entry {
              __typename
              sys {
                id
              }
            }
            ...ContentFormRows
          }
        }
      }
    }
  }

  query GenericPageBottomSection(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: genericPage(id: $id, locale: $locale, preview: $preview) {
        bottomSection: bottomSectionCollection(limit: 3) {
          items {
            ...CommonContentRows
            contentEntryCollection(limit: 4) {
              items {
                ... on contentful_Entry {
                  __typename
                  sys {
                    id
                  }
                }
                ...TextAndMediaLayout
                ...PromoBlock
                ...LinkboxCarousel
                ...PageContentAccordionList
              }
            }
          }
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default GenericPage
